<template>
  <div>
    <AppSpinnerBig :loading="loadingOnfido"></AppSpinnerBig>

    <AppModal v-model="showModal" :title="$t('PageOnfido.Title').value" :class="animationClass">
      <template #header>
        <AppModalHeader>
          {{ $t('PageOnfido.Title').value }}
          <template #right>
            <AppBackButton @click="back" />
          </template>
        </AppModalHeader>
      </template>

      <OnfidoSelectDocument @select="select"></OnfidoSelectDocument>
    </AppModal>

    <Onfido @init="init" @loaded="loaded" @tryAgain="tryAgain" @successful="done"> </Onfido>
  </div>
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import { ref } from '@vue/composition-api'
import OnfidoSelectDocument from '@galileo/components/Views/Identification/OnfidoSelectDocument'
import Onfido from '@galileo/components/Views/Identification/Onfido'

import {
  useTransition,
  useAlert,
  AppBackButton,
  AppModal,
  AppModalHeader,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

export default {
  name: 'AccountOnfido',
  components: {
    AppBackButton,
    AppModal,
    AppModalHeader,
    AppSpinnerBig,
    OnfidoSelectDocument,
    Onfido,
  },

  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const { add: addAlert } = useAlert()

    const showModal = ref(false)
    const loadingOnfido = ref(false)
    const animationClass = useTransition('blur', loadingOnfido)

    let onfidoCallback
    const init = (ev) => {
      onfidoCallback = ev
      onfidoCallback.showStatus()
    }

    const loaded = () => {
      loadingOnfido.value = false
    }

    const select = (document) => {
      loadingOnfido.value = true
      onfidoCallback.selectDocument(document)
    }

    const tryAgain = () => {
      showModal.value = true
    }

    const done = () => {
      if (showModal.value) {
        addAlert('Identification successful')
      }
      router.push('/account')
    }

    const back = () => {
      // a bit complicated since eventually Onfido cluttered the history but didn't clean it up
      router.goBackSteps(1)
    }

    return {
      showModal,
      init,
      loaded,
      select,
      done,
      back,
      tryAgain,
      loadingOnfido,
      animationClass,
      $t,
    }
  },
}
</script>

<style scoped></style>
